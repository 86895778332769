@import '../../mixins/input.scss';
@import '../../mixins/unit.scss';

.container{
    text-align: right;
    margin-top: 1rem;
    .slab-container{
        background-color: unset;
        box-shadow: unset;
        padding-bottom: unset;
        margin-top: unset;
        padding-top: unset;
        padding-right: unset;
    }
    .choices{
        background-color: unset;
        box-shadow: unset;
    }
}