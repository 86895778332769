.add-company-modal{
//   width: 66vw;    
//   max-width: 66vw;
  .modal-content{
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      .modal-header{
          border: unset;
          .modal-title{
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .close-icon{
                  cursor: pointer;
              }
              h4{
                  text-align: center;
                  font-size: 1.5 rem;
                  color: #390239;
                  font-weight: bold;
              }
          }
      }
      .modal-body{
          width: 380px;
          // height: 18.125rem;
          height: 18vw;
          border: unset;
          background-color: white;
          align-self: center;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 2rem;
          .form-group{
display: flex;
justify-content: space-between;
align-items: center;
              input{
                  width: 220px;
                  margin-inline-end: 1rem;
              }
          }
        //   .add-company{
        //       height: 100%;
        //       display: flex;
        //       flex-direction: column;
        //       justify-content: space-evenly;
        //       &-email,&-password{
        //           display: flex;
        //           align-items: center;
        //           justify-content: space-between;
        //           .error-msg{
        //               position: absolute;
        //               top: 40%;
        //               right: 40%;
        //               color: #DC3545;
        //           }
        //       }
        //   }
          .add-btn{
              width: 11vw;
              border: unset;
              background-color: #390239;
              color: white;
              cursor: pointer;
              border-radius: 24px;
              align-self: center;
              &.disabled{
                  cursor: not-allowed;
              }
          }
      }
      .modal-footer{
          border: unset;
          display: flex;
          justify-content: center;
      }
  }
}