@import "../../mixins/input.scss";
@import "../../mixins/unit.scss";
@import "../../mixins/select.scss";
.error-msg{
  display: block;
  color: #DC3545;
  }
.choices {
  // height: 23.188rem;
  // height: 20%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: white;
  padding-right: 2rem;
  box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
  border-radius: 5px;
  margin-top: 1rem;
  text-align: right;
  &-angel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
    &-details {
      position: relative;
      left: 11%;
    }
  }
  &-type {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
    &-details {
      position: relative;
      left: 14%;
    }
  }
  &-start {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &-details {
      position: relative;
      left: 12%;
    }
  }
}
