.edit-wall{
max-width:80% !important;
width: 80% !important;
  .color {
    background-color: #f0f0f0;
    border-radius: 16px;
    padding: 1rem;
    margin-bottom: 1rem;
    &-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      .clear,
      .calc {
        width: 7.688rem;
        height: 2.5rem;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .clear {
        background-color: white;
        color: #390239;
        border: 1px solid #390239;
        margin-inline-end: 2rem;
      }
      .calc {
        background-color: #390239;
        color: white;
        border: unset;
      }
    }
  }
  &-container {
    display: flex;
    flex-direction: row-reverse;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .section {
    text-align: right;
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}