@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
// @import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
// @import url(https://fonts.googleapis.com/css?family=Montserrat:800);
.snip1214 {
  // font-family: "Raleway", Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
  margin: 40px 10px;
}
.snip1214 .plan {
  margin: 0;
  width: 25%;
  position: relative;
  float: right;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  input[type="radio"] {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    padding-right: 0;
    margin-inline: 10px;
  }
  input[type="radio"] {
    accent-color: #390239;
  }
  label {
    position: unset;
    right: 2.5rem;
    font-weight: unset;
    font-size: unset;
    margin-bottom: unset;
    color: #390239;
  }
}
.snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.snip1214 header {
  position: relative;
}
.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #390239;
  color: #ffffff;
  text-transform: uppercase;
}
.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
.snip1214 .plan-price {
  // font-family: "Montserrat", Arial, sans-serif;
  font-weight: 800;
  font-size: 2.4em;
  color: #390239;
}
.snip1214 .plan-type {
  opacity: 0.6;
}
.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
}
.snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
// .snip1214 .plan-features li:nth-child(even) {
//   background: rgba(0, 0, 0, 0.08);
// }
.snip1214 .plan-features i {
  margin-inline-end: 8px;
  opacity: 0.4;
}
.snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
}
.snip1214 .plan-select button {
  background-color: #390239;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  border: none;
}
.snip1214 .plan-select button:hover {
  background-color: #390239;
}
.snip1214 .plan-select button:disabled {
  // background-color: #390239;
  opacity: 0.5;
}
.snip1214 .featured {
  margin-top: -10px;
  background-color: #390239;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
  label {
    color: #ffffff;
  }
}
.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #ffffff;
}
.snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}
.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 50%;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
}
