.special-space{
    input:disabled {
        background: #dddddd;
        cursor: not-allowed;
      }
    .slab-container,.choices{
        box-shadow: unset;
        padding-top: unset;
        padding-bottom: unset;
    }
    .choices{
        height: unset;
    }
    &-length{
        margin-top: 1rem;
    }
    &-length,&-width{
        padding: 0rem 2rem 1rem 0rem;
    }
    &-walls{
        padding: 2rem;
        padding-top: unset;
    }
    // input:disabled{
    //     background-color: aqua;
    // }
}