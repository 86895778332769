html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  // overflow-y: hidden;
}
* {
  font-family: "Tajawal";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Tajawal", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Tajawal";
  src: local("Tajawal"), url(./fonts/Tajawal-Regular.ttf) format("truetype");
}
// @font-face {
//   font-family: "Amiri";
//   src: local("Amiri"), url(./fonts/Amiri-Regular.ttf) format("truetype");
// }
// Amiri
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// scrollbar styling
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
