@import "../../mixins/input.scss";
@import "../../mixins/unit.scss";
button.calc.disabled {
  cursor: not-allowed;
}
.activeBelt {
  opacity: 0.5;
}
.wall {
  .error-msg {
    color: #dc3545;
  }
  &-container {
    display: flex;
    flex-direction: row-reverse;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .section {
    width: 50%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
  text-align: right;
  padding-top: 2rem;
  &-btns {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    &-add-new {
      text-align: left;
      span {
        margin-inline-end: 1rem;
        color: #390239;
        font-size: 1rem;
      }
    }
    &-main {
      .clear,
      .calc {
        width: 7.688rem;
        height: 2.5rem;
        border-radius: 24px;
        border: unset;
      }
      .calc {
        background-color: #390239;
        color: white;
        &.disabled {
          cursor: not-allowed;
        }
      }
      .clear {
        background-color: white;
        border: 1px solid #390239;
        color: #390239;
        margin-inline-end: 1rem;
      }
    }
  }
  &-labels {
    max-width: 30rem;
    text-align: right;
    margin-top: 2rem;
    height: 100px;
    overflow: scroll;
    &-btns {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: end;
      margin-bottom: 1rem;
      button {
        // margin-inline-end: 2rem;
        background-color: #390239;
        color: white;
        width: 6.5rem;
        // height: 2rem;
        border-radius: 7px;
        .close-btn {
          stroke: white;
          margin-inline-end: 1rem;
          box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
        }
      }
      .saved-btn{
        background-color: #390239;
        color: white;

      }
      .not-saved-btn{
        background-color:
        white;
        color:
        #390239;

      }
    }
  }
  .unit,
  .quantity .unit {
    margin-inline-end: 1rem;
  }
  .doors {
    padding: 1rem;
    border-top: unset;
    box-shadow: unset;
    .unit {
      margin-inline-end: unset;
    }
    .obstacles {
      padding: 1rem;
    }
  }

  &-name,
  &-shape {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
    &-input {
      width: 26.3125rem;
    }
  }
  &-shape {
    align-items: flex-start;
    .shape {
      cursor: pointer;
      &.selected {
        path,
        g {
          fill: #390239;
        }
      }
    }
  }
  &-measure {
    width: 19.3125rem;
  }
  &-section {
    text-align: right;
    .choices {
      margin-bottom: 1rem;
    }
  }
  &-details {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
    padding-top: unset;
    padding-bottom: unset;
    &-selected-shape {
      margin-top: 5rem;
      svg {
        width: 8rem;
        height: 8rem;
      }
    }
    &-selected-measure {
      width: 20rem;
      .wall-side {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 1rem;
        input {
          margin-inline-end: 3.5rem;
        }
      }
    }
  }
  &-wanted {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    align-items: flex-end;
    margin-inline-end: 2rem;
    &-section {
      line-height: 4rem;
      background-color: white;
      border-radius: 5px;
      box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
      padding: 2rem;
      text-align: right;
      display: flex;
      flex-direction: column;
      input {
        margin-inline-end: 1rem;
        margin-inline-start: 1rem;
      }
    }
    .speical-spaces,
    .belts-slabs {
      background-color: white;
      width: 100%;
      text-align: right;
      margin-top: 2rem;
      padding: 2rem;
      border-radius: 16px;
      .div-unit {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      input {
        margin-inline-end: 1rem;
        margin-inline-start: 1rem;
      }
    }
    .color {
      background-color: #f0f0f0;
      border-radius: 16px;
      padding: 1rem;
      &-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        .clear,
        .calc {
          width: 7.688rem;
          height: 2.5rem;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .clear {
          background-color: white;
          color: #390239;
          border: 1px solid #390239;
          margin-inline-end: 2rem;
        }
        .calc {
          background-color: #390239;
          color: white;
          border: unset;
        }
      }
    }
    &-btns {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      &-clear,
      &-calc {
        width: 7.688rem;
        height: 2.5rem;
        border-radius: 24px;
      }
      &-clear {
        background-color: white;
        color: #390239;
        border: 1px solid #390239;
        margin-inline-end: 2rem;
      }
      &-calc {
        background-color: #390239;
        color: white;
        border: unset;
      }
    }
    .floor-wanted {
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      align-items: flex-end;
      &-section {
        width: 100%;
        margin-bottom: 1rem;
        background-color: white;
        border-radius: 5px;
        box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
        padding: 2rem;
        text-align: right;
        display: flex;
        flex-direction: row-reverse;
        // height: 11%;
        &-labels {
          margin-inline-start: 1rem;
        }
        &-labels,
        &-inputs {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        &-units {
          display: flex;
          flex-direction: column;
          // height: 60%;
          justify-content: space-around;
          margin-inline-end: 1rem;
        }
      }
      &-btns {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        &-clear,
        &-calc {
          width: 7.688rem;
          height: 2.5rem;
          border-radius: 24px;
        }
        &-clear {
          background-color: white;
          color: #390239;
          border: 1px solid #390239;
          margin-inline-end: 2rem;
        }
        &-calc {
          background-color: #390239;
          color: white;
          border: unset;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  &-slab {
    margin-top: 1rem;
    text-align: right;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
    &-nav {
      padding-top: 1rem;
      margin-right: 2rem;
      button {
        margin-inline-start: 1rem;
        border-radius: 5px;
        background-color: #f5f5f5;
        color: #390239;
        border: unset;
        padding: 0.5rem 1rem 0.5rem 1rem;
        &.active {
          background-color: #390239;
          color: white;
        }
      }
    }
  }
}
.radioBtns{
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  .radioBtn{
    display: flex;
  justify-content: center;
  gap: 0.25rem;
  align-items: center;
  input[type="radio"]{
    width: 1rem;
  }
  label{
    // width: auto;
    font-size: initial;
    position: initial;
    margin-bottom: 0;
    color: #555555;
  }

  }
}
.disable{
  background-color: #e0d9d9a7;
  cursor: not-allowed;

}