.side-menu{
    @media only screen and (max-width: 768px) {
        position: relative;
        width: 90%;
        left: 5%;
      }
      @media (min-width:769px) and (max-width:1440px) {
        right: 1%;
        // width: 25%;
    }
    
    position: absolute;
    // width: 15.75rem;
    width: 16%;
    // height: 14rem;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
    &-item{
        display: flex;
        justify-content: flex-end;
        &.disabled{
            pointer-events: none;
        }
        .borderDiv{
            .side-menu-link{
                text-decoration: none;
                &.disabled{
                    pointer-events: none;
                }
            }
            .active-icon{
                stroke: #390239;
            }
            padding: 0.5rem;
            border-bottom: 1px solid #D5D5D5;
            width: 80%;
            &.no-border{
                border:unset
            }
        }
        .pointer{
            visibility: hidden;
            width: 10px;
            height: 48px;
            background-color: #390239;
            margin-left: 0.5rem;
            &.show{
                visibility: visible;
                position: relative;
                left: 1rem;
            }
        }
        span{
            margin-right: 1rem;
            font-size: 1.125rem;
            color: #959595;
        }
        &:hover{
            cursor: pointer;
            span{
                font-size: 1.125rem;
                color: #390239;
                font-weight: bold;
            }
            .pointer{
                visibility: visible;
                width: 10px;
                height: 48px;
                background-color: #390239;
                margin-left: 0.5rem;
            }
        }
        &.show{
            cursor: pointer;
            span{
                font-size: 1.125rem;
                color: #390239;
                font-weight: bold;
            }
            .pointer{
                visibility: visible;
                width: 10px;
                height: 48px;
                background-color: #390239;
                margin-left: 0.5rem;
            }
        }
    }
}