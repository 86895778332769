.total-materials {
  direction: rtl;
  width: 100% !important;
  background-color: white !important;
  border-radius: 5px !important;
  box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3) !important;
  padding: 2rem !important;
  margin-bottom: 1rem;
}
.room-total {
  // margin-top: 3%;
  margin: 10px ;
}
h3 {
  color: #390239;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: right;
}
#table {
  margin: auto;
}
