input {
  width: 80%;
}
button.btn-search {
  border: unset;
  border-radius: 10px;
  background-color: #390239;
  color: white;
  padding: 5px 8px;
  margin-left: 10rem;
}
