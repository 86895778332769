@import "../../mixins/headerBtns.scss";

.navbar {
  background-color: #390239 !important;
  flex-direction: row-reverse !important;
  height: 20vh;
  margin-bottom: 2rem;
  a {
    color: white !important;
    // @media screen and (min-width: 990) {
    //   padding: 0.1rem 0.1rem !important;
    // }
  }
  .navbar-brand {
    a {
      color: white !important;
      margin-right: 4rem !important ;
    }
  }
  .nav-item.dropdown {
    margin-left: 5%;
    border: 1px solid white;
    border-radius: 24px;
    width: 140px;
    direction: rtl;
    ::after {
      position: relative;
      right: 45%;
    }
    .dropdown-menu.show {
      text-align: right;
      a {
        color: #390239 !important;
      }
    }
  }
  .mr-auto {
    background-color: white !important;
    border-radius: 24px;
    width: 165px;
    display: flex;
    justify-content: center;
    margin-left: 2%;
    a {
      color: #390239 !important;
    }
  }
}

.header {
  background-color: #390239;
  height: 20vh;
  margin-bottom: 3rem;

  &.landing {
    margin-bottom: unset;
  }
  &-upper {
    padding-top: 1.5rem;
    align-items: baseline;

    .logout {
      @include header-btn;
      cursor: pointer;
      position: relative;
      right: 7rem;
    }
    .login {
      @include header-btn;
      cursor: pointer;
      position: relative;
      right: 7rem;
    }
    &-btns {
      width: 30%;
      height: 4rem;
      display: flex;
      justify-content: space-between;
      position: relative;
      left: 5%;
      align-items: baseline;
      .header-logo {
        width: 20%;
        display: flex;
        align-items: center;
        margin-inline-start: 39%;
        .logo {
          margin-inline-end: 5%;
        }
      }
      .header-button {
        @include header-btn;
        text-decoration: unset;
        height: 2rem;
        &:hover,
        &.active {
          font-weight: bold;
          border-bottom: 4px solid white;
          cursor: pointer;
        }
      }
    }
  }
  &-lower {
    // padding-top: 4rem;
    button {
      @include header-btn;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
    }
    &-ctrls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 42%;
      .search-input {
        width: 28.5625rem;
        height: 2rem;
        text-align: right;
        border: unset;
        border-radius: 1rem;
        padding-right: 1rem;
      }
      .search-icon {
        position: relative;
        right: 29.5rem;
      }
    }
  }
  &-upper,
  &-lower {
    display: flex;
    justify-content: space-around;
  }
}
.dropdown{
  .btn{
    color: white;
    font-size: 20px;
  }
  i{
    svg{
      color: white;
    }
  }
    button.dropdown-toggle::after{
      display: none;
    }
}
.dropdown-menu {
  transform: translate(-116px, 54px) !important;
  .dropdown-item
  {
text-align: right;
    &:active,&:hover{
      background-color: #390239;
      color: white;
    }
  }
}
// @media screen and (max-width: 990) {
//   .nav {
//     gap: 1rem;
//   }
//   .navbar .mr-auto a {
//     font-size: 0.5rem;
//     padding: 0.2rem !important;
//   }
//   .header {
//     display: flex !important;
//     gap: 1;
//     align-items: flex-start;
//   }
// }
 @media screen and (max-width: 600px) {
  .navbar{
    .navbar-brand{
      width: 206px !important;
    }
    .nav.navbar-collapse{
  
        background-color: rgba(71, 7, 79, 0.5);
        z-index: 9999;
        padding: 8px;
        gap: 1rem;
      form{
        .btn-search{
          margin-left: 0;
        }
      }
    }
  }
  
}