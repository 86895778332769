.add-users-company-modal{
//   width: 66vw;    
//   max-width: 66vw;
.modal-content{
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  .modal-header{
      border: unset;
      .modal-title{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .close-icon{
              cursor: pointer;
          }
          h4{
              text-align: center;
              font-size: 1.5 rem;
              color: #390239;
              font-weight: bold;
          }
      }
  }
  .modal-body{
      width: 37vw;
      // height: 18.125rem;
      height: 48vh;
      border: unset;
      background-color: white;
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 2rem;
      input{
          width: 27vw;
          margin-inline-end: 1rem;
      }
      .form-group{
        display: flex;
    align-items: center;
    justify-content: space-between;
      }
      .add-users-company{
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          &-email,&-password{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .error-msg{
                  position: absolute;
                  top: 40%;
                  right: 40%;
                  color: #DC3545;
              }
          }
          .role{
            display: flex;
            justify-content: space-evenly;
            gap: 4rem;
            input{
              width: 1rem !important;
            }
          }
      }
      .add-btn{
          width: 11vw;
          border: unset;
          background-color: #390239;
          color: white;
          cursor: pointer;
          border-radius: 24px;
          align-self: center;
          &.disabled{
              cursor: not-allowed;
          }
      }
  }
  .modal-footer{
      border: unset;
      display: flex;
      justify-content: center;
  }
}
}