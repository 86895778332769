.btn-add{
  color: white;
  background-color: #390239 !important;
  float: left;

  
}
.btn-add:hover{
  background-color: white !important;
  color: #390239 !important;

}
.clear{
  clear: both;
}
.btn-add-user{
  color: white;
  background-color: #390239 !important;
  
  
}
.btn-add-user:hover{
  background-color: #390239be !important;
color: white !important;

  // color: #390239 !important;

}