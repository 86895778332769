.login-modal{
    width: 66vw;    
    max-width: 66vw;
    .modal-content{
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        .modal-header{
            border: unset;
            .modal-title{
                width: 100%;
                .close-icon{
                    cursor: pointer;
                }
                h4{
                    text-align: center;
                    font-size: 2rem;
                    color: #390239;
                    font-weight: bold;
                }
            }
        }
        .modal-body{
            width: 47vw;
            // height: 18.125rem;
            height: 30vw;
            border: unset;
            background-color: white;
            align-self: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 2rem;
            input{
                width: 27vw;
                margin-inline-end: 1rem;
            }
            .login{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                &-username,&-password{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .error-msg{
                        position: absolute;
                        top: 40%;
                        right: 40%;
                        color: #DC3545;
                    }
                }
            }
            .login-btn{
                width: 11vw;
                border: unset;
                background-color: #390239;
                color: white;
                cursor: pointer;
                border-radius: 24px;
                align-self: center;
                &.disabled{
                    cursor: not-allowed;
                }
            }
        }
        .modal-footer{
            border: unset;
            display: flex;
            justify-content: center;
        }
    }
}
@media screen and (max-width: 600px) {
    .login-modal{
        width:auto;    
        max-width:100%;
        
        .modal-content{

    .modal-body{
width: 90%;
padding: 3%;
            height: auto;
            gap: 2rem;
            .login{
gap: 2rem;
                .login-username,.login-password{
                    input{
                        width: auto;
                    }
                    span{
    
                        text-align: right;
                    }
                }
            }
            .login-btn {
                width: auto;
            }
    }
        }
    }
}