@import '../../mixins/input.scss';
@import '../../mixins/unit.scss';
@import '../../mixins/select.scss';

.slab-container{
    margin-bottom: 1rem;
        margin-top: 1rem;
        border-top: unset;
        padding-top: 2rem;
        border-radius: 5px;
        box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
        padding:2rem;
        background-color: white;
        text-align: right;
        .error-msg{
        color: #DC3545;
        }
    .slab-measure{
        padding: 1rem;
        display: flex;
        flex-direction: column;
        direction: rtl;
        .unit{
            margin-inline-start: 1rem;
        }
        &-tile-name{
            margin-bottom: 1rem;
            display: flex;
            .dropdown{
                margin-inline-start: 2rem;
            }
        }
        &-tile-length,&-tile-width,&-tile-spacing{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }
        &-labels,&-inputs{
            direction: rtl;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .def{
                margin-bottom: 1rem;
            }
            input{
                margin-bottom: 1rem;
                margin-inline-start: 1rem;
            }
            .select{
                margin-inline-start: 1rem;
                margin-bottom: 1rem;
            }
            
        }
        
    }
    .cyclo-measure{
        display: flex;
        flex-direction: row;
        direction: rtl;
        &-labels,&-inputs{
            direction: rtl;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &--fig{
                .fig{
                    margin-inline-start: 9rem;
                }
            }
            .def{
                margin-bottom: 1rem;
            }
            input{
                margin-bottom: 1rem;
                margin-inline-start: 1rem;
            }
            .select{
                margin-inline-start: 1rem;
                margin-bottom: 1rem;
            }
            .unit{
                margin-inline-start: 1rem;
            }
        }    
    }
}