.client-orders{
    direction: rtl;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
    padding: 2rem;

    .colors{
        display: flex;
        gap: 2rem;
    }
    .out-company{
        display: inline-block;
        padding: 1rem;
        background-color: #eee;
        border: 1px solid #555555;
    }
    .in-company{
        display: inline-block;
        padding: 1rem;
        background-color: #fff;
        border: 1px solid #555555;

    }

}