input {
  width: 10.75rem;
  height: 2.5rem;
  text-align: right;
  border: unset;
  border-radius: 1rem;
  padding-right: 1rem;
  background-color: #f7f7f7;
  &:focus {
    border: unset;
    outline: none;
  }
  @media only screen and (max-width: 400px) {
    width: 5.75rem;
  }
}
