.policy{
  padding-bottom: 1rem;
  .heading{
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.5;
    color: #390239;
  }
  .desc{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2;
    color: #390239;
  }
  .bold-800{
    font-weight: 800;
    padding-inline: 5px;
  }
  .bold-600{
    font-weight: 600;
    padding-inline: 5px;
  }
  .bold-400{
    font-weight: 400;
    padding-inline: 5px;
  }
}