.App {
  font-family: "Tajawal";
  min-height: 100vh;
  background-color: #f5f5f5;
  &-body {
    // height: 73vh;
    // overflow-y: auto;
    padding: unset;
    .nav-tabs {
      width: 76%;
      margin-left: 21.5%;
      direction: rtl;
      padding-right: unset;
      .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-left: 1px solid #e6e6e6;
        width: 8.6rem;
        height: 2.5rem;
        color: white;
        cursor: pointer;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #959595;
        }
        &.active {
          background-color: #390239;
          color: white;
          a {
            color: white;
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 600px) {
//   .App {
//     background-color: #390239;
//   }
// }
