.terms{
  padding-bottom: 1rem;
  .heading{
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.5;
    color: #390239;
  }
  .desc{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    color: #390239;
  }
  li{
    line-height: 1.75;
  }
}