@import "../../mixins/input.scss";
@import "../../mixins/unit.scss";

.doors {
  .error-msg {
    color: #dc3545;
    position: absolute;
    bottom: 0px;
  }
  margin-bottom: 1rem;
  border-top: unset;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  background-color: white;
  text-align: right;
  border-radius: 5px;
  .single-door,
  .single-obstacle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap-reverse;
    width: 100%;
    &-add-door {
      width: 12rem;
      position: relative;
      top: 1rem;
      :hover {
        cursor: pointer;
      }
    }
  }
  .single-door {
    &-details {
      input {
        margin-inline-end: 1rem;
        margin-inline-start: 1rem;
      }
      .unit {
        margin-inline-end: 1rem;
        align-self: center;
      }
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 0.5rem;
      height: 6rem;
      @media only screen and (max-width: 400px) {
        justify-content: center;
      }
    }
  }
  .single-obstacle {
    height: 6rem;
    &-add-obstacle {
      display: flex;
      align-items: flex-end;
      width: 8rem;
      height: 6rem;
      padding-bottom: 0.5rem;
      flex-direction: row-reverse;
      :hover {
        cursor: pointer;
      }
    }
    &-details {
      width: 20rem;

      &-length,
      &-width {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
