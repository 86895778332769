.landing-page-container {
  background-image: url("../../assets/Web 1920 – 5.png");
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .header-logo {
      width: 20%;
      display: flex;
      align-items: center;
      // margin-inline-start: 39%;
      .logo {
        margin-inline-end: 5%;
      }
    }
    span {
      color: white;
      font-size: 2rem;
      font-weight: bold;
    }
    button {
      border: unset;
      border-radius: 10px;
      background-color: #390239;
      color: white;
      padding: 0.5% 1%;
      // margin-inline-start: 24%;
    }
  }
  &-overview {
    display: flex;
    &-body {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: white;
      margin-top: 10%;
      width: 30%;
      .body-header {
        font-size: 2rem;
        font-weight: lighter;
      }
      .body-text {
        font-size: 1rem;
        font-weight: lighter;
      }
      p {
        font-size: 2.125rem;
        font-weight: bold;
        text-align: right;
      }
      button {
        border: unset;
        border-radius: 10px;
        color: #390239;
        background-color: white;
        padding: 1% 5%;
        margin-top: 5%;
      }
    }
  }
  &-count {
    display: flex;
    justify-content: space-around;
    margin-top: 20%;
    &-unit {
      display: flex;
      flex-direction: column;
      align-items: center;
      .unit-header {
        font-size: 2.875rem;
        font-weight: bold;
        color: #390239;
      }
      .unit-body {
        font-size: 1.25rem;
        color: #3b3a3a;
        font-weight: bold;
      }
    }
  }
  &-images {
    display: flex;
    margin-top: 5%;
    .text-left {
      .line {
        width: 8%;
        height: 0.4%;
        background-color: #390239;
        align-self: flex-end;
        border-radius: 10px;
      }
      .text-header {
        font-size: 2.438rem;
      }
      padding-top: 10%;
      padding-right: 5%;
      width: 50%;
      display: flex;
      flex-direction: column;
      text-align: right;
      font-size: 1.438rem;
      color: #390239;
    }
    .images-right {
      width: 50%;
      .image-large {
        background-image: url("../../assets/MRTPJW5I7U.png");
        background-size: cover;
        width: 664px;
        height: 396px;
        position: relative;
        .largeOpacity {
          position: absolute;
          top: 6%;
          width: 91%;
          height: 85%;
          left: 4.5%;
          background-color: rgba($color: #707070, $alpha: 0.1);
          border-radius: 30px;
          color: white;
          font-size: 2rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .images-small {
        display: flex;
        .small-left {
          background-image: url("../../assets/00RNNUWGLM.png");
          width: 341px;
          height: 326px;
          position: relative;
          .leftOpacity {
            position: absolute;
            background-color: rgba(112, 112, 112, 0.1);
            color: white;
            font-size: 1rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 83%;
            height: 81%;
            top: 8%;
            left: 8%;
            border-radius: 25px;
          }
        }
        .small-right {
          background-image: url("../../assets/3H89EH3WIJ.png");
          width: 341px;
          height: 326px;
          position: relative;
          .rightOpacity {
            position: absolute;
            background-color: rgba(112, 112, 112, 0.1);
            color: white;
            font-size: 1rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 83%;
            height: 81%;
            top: 8%;
            left: 8%;
            border-radius: 25px;
          }
        }
      }
    }
    .images-left {
      width: 50%;
    }
  }
  &-reg {
    margin-top: 5%;
    display: flex;
    padding-right: 15%;
    &-text {
      text-align: right;
      display: flex;
      flex-direction: column;
      padding-top: 10%;
      align-items: flex-end;
      .reg-header {
        color: #390239;
        font-size: 1.813rem;
        font-weight: bold;
      }
      .reg-text {
        color: #2b0b3d;
        font-size: 1.438rem;
      }
      button {
        border: unset;
        border-radius: 10px;
        background-color: #390239;
        color: white;
        width: 25%;
      }
    }
  }
  &-def {
    display: flex;
    flex-direction: column;
    padding-inline-end: 14%;
    padding-bottom: 2rem;
    p {
      font-size: 2.313rem;
      color: #390239;
      font-weight: bold;
    }
    text-align: center;
    .def-unit {
      display: flex;
      justify-content: center;
      margin-left: 20%;
      align-self: flex-end;
      width: 50%;
      .unit-shape {
        .shape {
          width: 50px;
          height: 50px;
          background-color: #390239;
          border-radius: 50px;
        }
        .line {
          margin-top: 35%;
          width: 27px;
          height: 5rem;
          border-right: 3px solid #bcbcbc;
          margin-bottom: 35%;
        }
      }
      .unit-text {
        text-align: right;
        margin-right: 4%;
        span {
          font-size: 1.813rem;
          font-weight: bold;
          color: #390239;
        }
        p {
          font-size: 1.438rem;
          color: #2b0b3d;
          // width: 5%;
        }
      }
    }
  }
  &-footer {
    display: flex;
    background-color: #390239;
    justify-content: space-around;
    margin-top: 10%;
    ul {
      list-style-type: none;
      li {
        a {
          color: white;
          text-decoration: none;
          line-height: 2;
        }
      }
    }
    .credits {
      margin-top: 1rem;
      display: flex;
      // flex-direction: column;
      align-items: center;
      gap: 1rem;
      .credit {
        width: 80px;
        color: white;
      }
    }
    .contact-us {
      h4,
      p,
      a {
        text-align: center;
        color: white;
        text-decoration: none;
      }
    }
    .footer-text {
      text-align: right;
      span {
        color: white;
        font-size: 2.625rem;
      }
      p {
        font-size: 0.875rem;
        color: white;
      }
    }
    .footer-sub {
      text-align: right;
      span {
        color: white;
      }
      .sub-btn {
        position: relative;
        margin-top: 5%;
        button {
          position: absolute;
          border: unset;
          border-radius: 10px;
          background-color: #390239;
          color: white;
          left: 1%;
          top: 17%;
        }
        input {
          width: 25rem;
        }
      }
    }
  }
  form {
  }
}

@media screen and (max-width: 600px) {
  .landing-page-container {
    background-color: #390239;
    background-image: none;
    // width: 100%;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .header-logo {
        width: 20%;
        display: flex;
        align-items: center;
        margin-inline-start: 12%;
        .logo {
          margin-inline-end: 5%;
        }
      }
      span {
        font-size: 1.2rem;
      }
      button {
        border: unset;
        border-radius: 10px;
        background-color: transparent;
        color: white;
        // padding: 0.5% 1%;
        padding: 0.5rem 1rem;
        // margin-top: 1rem;
        margin-inline-start: 0;
      }
    }
    &-overview {
      display: flex;
      &-image {
        display: none;
      }
      &-body {
        text-align: center;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        margin-top: 10%;
        margin-right: 0;
        width: 100%;
        .body-header {
          font-size: 2rem;
          font-weight: lighter;
        }
        .body-text {
          font-size: 1rem;
          font-weight: lighter;
        }
        p {
          font-size: 1.4rem;
          font-weight: bold;
          text-align: center;
        }
        button {
          border: unset;
          border-radius: 10px;
          color: #390239;
          background-color: white;
          padding: 1% 5%;
          margin-top: 5%;
        }
      }
    }
    &-count {
      background-color: white;
      display: flex;
      justify-content: space-around;
      margin-top: 10%;
      &-unit {
        display: flex;
        flex-direction: column;
        align-items: center;
        .unit-header {
          font-size: 2.275rem;
          font-weight: bold;
          color: #390239;
        }
        .unit-body {
          font-size: 1.05rem;
          color: #3b3a3a;
          font-weight: bold;
        }
      }
    }
    &-images {
      background: white;
      flex-direction: column;
      margin-top: 0;
      .text-left {
        .line {
          width: 8%;
          height: 0.4%;
          background-color: #390239;
          align-self: flex-end;
          border-radius: 10px;
        }
        span {
          font-size: 1rem;
        }
        .text-header {
          font-size: 2rem;
        }
        // padding-top: 10%;
        // padding-right: 5%;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: right;
        font-size: 1.4rem;
        color: #390239;
      }
      .images-right {
        width: 100%;
        .image-large {
          background-image: url("../../assets/MRTPJW5I7U.png");
          background-size: cover;
          width: 93%;
          height: 300px;
          position: relative;
          .largeOpacity {
            position: absolute;
            top: 6%;
            width: 95%;
            height: 85%;
            left: 6%;
            background-color: rgba($color: #707070, $alpha: 0.1);
            border-radius: 30px;
            color: white;
            font-size: 2rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .images-small {
          display: flex;
          .small-left {
            background-image: url("../../assets/00RNNUWGLM.png");
            width: 50%;
            height: 326px;
            position: relative;
            .leftOpacity {
              position: absolute;
              background-color: rgba(112, 112, 112, 0.1);
              color: white;
              font-size: 1rem;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 88%;
              height: 81%;
              top: 8%;
              left: 12%;
              border-radius: 25px;
              span {
                text-align: center;
              }
            }
          }
          .small-right {
            background-image: url("../../assets/3H89EH3WIJ.png");
            width: 50%;
            height: 326px;
            position: relative;
            .rightOpacity {
              position: absolute;
              background-color: rgba(112, 112, 112, 0.1);
              color: white;
              font-size: 1rem;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 83%;
              height: 81%;
              top: 8%;
              left: 15%;

              border-radius: 25px;
              span {
                text-align: center;
              }
            }
          }
        }
      }
      .images-left {
        width: 50%;
      }
    }
    &-reg {
      background: white;
      margin-top: 0;
      display: flex;
      padding: 1rem;
      &-image {
        img {
          display: none;
        }
      }
      &-text {
        text-align: right;
        display: flex;
        flex-direction: column;
        padding-top: 10%;
        align-items: center;
        .reg-header {
          color: #390239;
          font-size: 1.23rem;
          font-weight: bold;
        }
        .reg-text {
          color: #2b0b3d;
          font-size: 1.1rem;
        }
        button {
          border: unset;
          border-radius: 10px;
          background-color: #390239;
          color: white;
          width: 30%;
          padding: 1rem;
          text-align: center;
        }
      }
    }
    &-def {
      background: white;
      display: flex;
      flex-direction: column;
      padding-inline-end: 5%;
      padding-inline-start: 5%;
      text-align: center;
      p {
        font-size: 1.6rem;
        color: #390239;
        font-weight: bold;
      }
      font-size: 1rem;

      text-align: center;
      .def-unit {
        display: flex;
        justify-content: center;
        margin-left: 8%;
        align-self: flex-end;
        width: 100%;
        .unit-shape {
          .shape {
            width: 20px;
            height: 20px;
            background-color: #390239;
            border-radius: 50px;
          }
          .line {
            margin-top: 35%;
            width: 12px;
            height: 5rem;
            border-right: 3px solid #bcbcbc;
            margin-bottom: 35%;
          }
        }
        .unit-text {
          text-align: right;
          margin-right: 4%;
          span {
            font-size: 1.2rem;
            font-weight: bold;
            color: #390239;
          }
          p {
            font-size: 1rem;
            color: #2b0b3d;
            // width: 5%;
          }
        }
      }
    }
    &-footer {
      display: flex;
      flex-direction: column;
      background-color: #390239;
      justify-content: space-around;
      margin-top: 0;
      padding-top: 5%;
      padding-bottom: 5%;
      .footer-text {
        text-align: center;
        padding: 0.5rem;
        span {
          color: white;
          font-size: 2.2rem;
        }
        p {
          font-size: 0.875rem;
          color: white;
        }
      }
      .footer-sub {
        text-align: center;
        span {
          color: white;
        }
        .sub-btn {
          position: relative;
          margin-top: 5%;
          button {
            position: absolute;
            border: unset;
            border-radius: 10px;
            background-color: #390239;
            color: white;
            left: 9%;
            top: 17%;
          }
          input {
            width: 90%;
            margin: auto;
          }
        }
      }
    }
  }
}
