.user-data-container {
  margin-left: 0px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
