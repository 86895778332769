.add-belt {
  .slab-container {
    box-shadow: unset;
    margin-bottom: unset;
    padding-top: unset;
  }
  &-choices {
    padding: 2rem;
    padding-top: unset;
    &-direction {
      .direction-fig {
        margin-inline-end: 5rem;
      }
    }
    &-walls {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      height: 5rem;
      justify-content: right;
      align-items: center;
      margin-top: 1rem;
    }
    &-btns {
      text-align: left;
      margin-top: 1rem;
      .add-label {
        margin-inline-start: 1rem;
        color: #390239;
      }
    }
  }
}

.close-button {
  margin-inline-end: 2rem;
  background-color: #390239;
  color: white;
  width: 6.5rem;
  height: 2rem;
  border-radius: 7px;
  border: unset;
  .close-btn {
    stroke: white;
    margin-inline-end: 1rem;
    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
  }
}

.add-belt-walls {
  .close-button {
    margin-inline-end: 0.2rem;
    background-color: #390239;
    color: white;
    width: 2.8rem;
    height: 2rem;
    border-radius: 7px;
    border: unset;
    .close-btn {
      width: 0.5rem;
      stroke: white;
      margin-inline-end: 0.4rem;
      box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
    }
  }
}
