@import '../../../mixins/input.scss';
@import '../../../mixins/unit.scss';
@import '../../../mixins/select.scss';

.cyclo-edit{
    .error-msg{
        color: #DC3545;
    }
    &-container{
        display: flex;
        flex-direction: row-reverse;
        @media only screen and (max-width: 1024px) {
            flex-direction: column;
          }
    }
    // width: 41.625rem;
    width: 100%;
    @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    // height: 100vh;
    border-radius: 5px;
    padding: 2rem;
    text-align: right;
    margin-top: unset;
    
    &-labels{
        label{
            margin-bottom: unset;
        }
        &-btns{
            margin-bottom: 1rem;
            button{
                margin-inline-end: 2rem;
                background-color: #390239;
                color: white;
                width: 6.5rem;
                height: 2rem;
                border-radius: 7px;
                border: unset;
                .close-btn{
                    stroke: white;
                    margin-inline-end: 1rem;
                    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
                }
            }
        }
    }
    .quantity-doors-container{
        background-color: white;
        box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
        border-radius: 5px;
        margin-top: 1rem;
    .quantity{
        padding:1rem;
        padding-right: 2rem;
        position: relative;
        input{
            margin-inline-end: 3.5rem;
        }
        .unit{
            margin-inline-end: 1rem;
        }
    }
}
    .slab-measure{
        height: 24.188rem;
        background-color: white;
        padding-top: 2rem;
        border-radius: 5px;
        box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
        padding: 2rem;
    }
    // .slab-measure,.cyclo-measure{
    //     padding-right: 2rem;
    // }
    .slab-measure,.cyclo-measure{
        direction: rtl;
        &-cut-type{
            width: 70% !important;
            .fig{
                position: relative;
                right: 10%;
            }
        }
        &-length,&-width,&-cut-type,&-height{
            // width: 20rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 63%;
            .select{
                margin-inline-start: unset;
            }
            
        }
    }
    &-wanted{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        align-items: flex-end;
        &-btn{
            margin-inline-end: 2rem;
            background-color: #390239;
            color: white;
            width: 6.5rem;
            height: 2rem;
            border-radius: 7px;
            border: unset;
            margin-top: 5%;
            align-self: center;
            &.disabled{
                cursor: not-allowed;
            }
        }
        &-section{
            // width: 35rem;
            
            background-color: white;
            border-radius: 5px;
            box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
            padding: 2rem;
            text-align: right;
            display: flex;
            flex-direction: row-reverse;
            height: 50%;
            &-labels{
                margin-inline-start: 2rem;
            }
           
            &-labels,&-inputs{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            &-units{
                display: flex;
                flex-direction: column;
                height: 50%;
                justify-content: space-around;
                margin-inline-end: 1rem;
            }
        }
        &-btns{
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            &-clear,&-calc{
                width: 7.688rem;
                height: 2.5rem;
                border-radius: 24px;
                &.disabled{
                    cursor:not-allowed;
                }
            }
            &-clear{
                background-color: white;
                color: #390239;
                border: 1px solid #390239;
                margin-inline-end: 2rem;
            }
            &-calc{
                background-color: #390239;
                color: white;
                border: unset;
            }
        }
    }
}