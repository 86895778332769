.not-found{
// height:calc( 80);
height: 70vh;
display: flex;
justify-content: center;
align-items: center;
h2{
  font-size: 40px;
  font-weight: 600;
}
p{
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  a{
    font-size: 25px;
    font-weight: 400;
    color: #390239;
    text-decoration: underline;
  }
}

}