@import '../../../mixins/input.scss';
@import '../../../mixins/unit.scss';

.floor-edit{
    .error-msg{
        color: #DC3545;
    }
    &-container{
        display: flex;
        flex-direction: row-reverse;
        @media only screen and (max-width: 1024px) {
            flex-direction: column;
          }
    }
    .section{
        width: 100%;
        @media only screen and (max-width: 1024px) {
            width: 100%;
          }
    }
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
    text-align: right;
    // padding-top: 2rem;
    &-btns{
        display: flex;
        margin-top: 1rem;
        &-add-new{
            text-align: left;
            span{
                margin-inline-end: 1rem;
                color: #390239;
                font-size: 1rem;
            }
        }
        &-main{
            .clear,.calc{
                width: 7.688rem;
                height: 2.5rem;
                border-radius: 24px;
                border: unset;
            }
            .calc{
                background-color: #390239;
                color: white;
                &.disabled{
                    cursor:not-allowed;
                }
            }
            .clear{
                background-color: white;
                border: 1px solid #390239;
                color: #390239;
                margin-inline-end: 1rem;
            }
        }
    }
    &-labels{
        text-align: right;
        margin-top: 2rem;
        // visibility: hidden;
        width: 40rem;
        &-btns{
            display: flex;
            justify-content: end;
            overflow-y: scroll;
            margin-bottom: 1rem;
            button{
                margin-inline-end: 2rem;
                background-color: #390239;
                color: white;
                width: 6.5rem;
                height: 2rem;
                border-radius: 7px;
                border: unset;
                // margin-inline-end: 2rem;
                // background-color: #390239;
                // color: white;
                // width: 4rem;
                // height: 4rem;
                // display: flex;
                // align-items: center;
                // flex-direction: column;
                // border-radius: 7px;
                .close-btn{
                    stroke: white;
                    margin-inline-end: 1rem;
                    box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
                }
            }
        }
    }
    .unit,.quantity .unit{
        margin-inline-end: 1rem;
    }
    .doors{
        .single-door-details{
            width: 35rem;
        }
        .unit{
            margin-inline-end: unset;
        }
        .obstacles{
            padding: 1rem;
        }
        .door-width{
            width: 50%;
        }
    }
    &-name,&-shape{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 2rem;
        &-input{
            width: 26.3125rem;
        }
    }
    &-shape{
        align-items: flex-start;
        .shape{
            cursor: pointer;
            &.selected{
                path,g{
                    fill: #390239;
                }
            }
        }
    }
    &-measure{
        width: 19.3125rem;
    }
    &-details{
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        padding-top: unset;
        &-selected-shape{
            margin-top: 5rem;
            display: flex;
            align-items: center;
            svg{
                width: 8rem;
                height: 8rem;

            }
        }
        &-selected-measure{
            width: 20rem;
            .floor-side{
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                margin-bottom: 1rem;
                input{
                    margin-inline-end: 3.5rem;
                }
            }
        }
    }
    &-wanted{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        align-items: flex-end;
        width: 50%;
        margin-inline-end: 2rem;
        @media only screen and (max-width: 1024px) {
            width: 100%;
          }
          &-btn{
            margin-inline-start: 15%;
            background-color: #390239;
            color: white;
            width: 6.5rem;
            height: 2rem;
            border-radius: 7px;
            border: unset;
            margin-top: 5%;
            align-self: center;
            &.disabled{
                cursor: not-allowed;
            }
          }
        &-section{
            background-color: white;
            border-radius: 5px;
            box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
            padding: 2rem;
            text-align: right;
            display: flex;
            flex-direction: row-reverse;
            // height: 20%;
            line-height: 4rem;
            &-labels{
                margin-inline-start: 1rem;
            }
            &-labels,&-inputs{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            &-units{
                display: flex;
                flex-direction: column;
                // height: 60%;
                justify-content: space-between;
                margin-inline-end: 1rem;
            }
        }
        &-btns{
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            &-clear,&-calc{
                width: 7.688rem;
                height: 2.5rem;
                border-radius: 24px;
            }
            &-clear{
                background-color: white;
                color: #390239;
                border: 1px solid #390239;
                margin-inline-end: 2rem;
            }
            &-calc{
                background-color: #390239;
                color: white;
                border: unset;
            }
        }
    }
}