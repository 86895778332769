.unit{
    color: #707070;
    font-size: 0.875rem;
}
label{
    position: relative;
    right: 2.5rem;
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    color: #390239;
}
.add-label{
    margin-inline-end: 1rem;
}
.add-label,.add-icon{
    color: #390239;
}
.def{
    font-size: 1rem;
}