@import "../../mixins/input.scss";

.client-data {
  .error-msg {
    color: #dc3545;
  }
  @media only screen and (max-width: 768px) {
    width: unset;
    left: unset;
    margin-top: 2rem;
  }
  @media (min-width: 769px) and (max-width: 1440px) {
    left: 30%;
  }
  position: relative;
  left: 44%;
  width: 55%;
  height: 100%;
  background-color: white;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba($color: #555555, $alpha: 0.3);
  .form-name {
    font-size: 1.5rem;
    @media only screen and (max-width: 400px) {
      font-size: 1rem;
    }
    color: black;
    font-weight: bold;
  }
  &-name,
  &-phone,
  &-address,
  &-email {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;
    width: 85%;
    span {
      font-size: 0.875rem;
      color: black;
    }
    input {
      // width: 34.4375rem;
      width: 100%;
      height: 3rem;
      border-radius: 1rem;
      border: unset;
      background-color: #f7f7f7;
      margin-top: 1rem;
    }
  }
  &-save {
    background-color: #390239;
    color: white;
    width: 7.6875rem;
    height: 2.5rem;
    border-radius: 24px;
    margin-top: 2rem;
    &.disabled {
      cursor: not-allowed;
    }
  }
}
