@import "../../mixins/input.scss";
@import "../../mixins/unit.scss";
@import "../../mixins/select.scss";

.room-container {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  .error-msg {
    color: #dc3545;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  input {
    margin-inline-end: 3.5rem;
    @media only screen and (max-width: 400px) {
      margin-inline-end: 0.5rem;
    }
  }
  .unit {
    margin-inline-end: 2rem;
  }
  label {
    font-size: 18px;
    color: #390239;
    font-weight: bold;
  }
  &-section {
    width: 45%;
    margin-top: 2.5%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    &-btns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .add-new-room {
        margin-inline-end: 7rem;
        @media only screen and (max-width: 768px) {
          margin-inline-end: 2rem;
        }
        span {
          margin-inline-end: 1rem;
        }
      }
      &-clear,
      &-calc {
        width: 20%;
        // height: 2.5rem;
        border-radius: 24px;
        @media only screen and (max-width: 400px) {
          width: 100%;
        }
        &.disabled {
          cursor: not-allowed;
        }
      }
      &-clear {
        background-color: white;
        color: #390239;
        border: 1px solid #390239;
        margin-inline-end: 2rem;
      }
      &-calc {
        background-color: #390239;
        color: white;
        border: unset;
      }
    }
  }
  .room {
    background-color: white;
    padding: 2rem;

    @media only screen and (max-width: 1024px) {
      padding: 2rem;
    }
    @media only screen and (max-width: 400px) {
      // padding: 2rem;
      padding: 1rem;
    }
    &-name {
      display: flex;
      input {
        margin-inline-end: 2rem;
        width: 95%;
      }
    }
    &-shape {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      .shape {
        cursor: pointer;
        &.selected {
          path,
          g {
            fill: #390239;
          }
        }
      }
    }
    .doors {
      padding: unset;
      .single-door-details {
        width: 32rem;
        @media only screen and (max-width: 400px) {
          // padding: 2rem;
          // padding: 1rem;
          width: 100%;
        }
      }
      input {
        margin-inline-end: 2rem;
      }
    }
    &-measure {
      &-shape {
        display: flex;
        align-items: center;
        svg {
          width: 8rem;
          height: 8rem;
          @media only screen and (max-width: 400px) {
            // padding: 2rem;
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
    &-measures {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      @media only screen and (max-width: 400px) {
        flex-direction: column;
        align-items: center;
      }
      &-inputs {
        &-single {
          display: flex;
          flex-direction: row-reverse;
          margin-bottom: 1rem;
          align-items: center;
        }
      }
    }
    &-container-total {
      margin-inline-end: 2rem;
      width: 50%;
      position: relative;
      @media only screen and (max-width: 768px) {
        margin-top: 20%;
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      &-save {
        position: absolute;
        left: 0;
        button {
          background-color: #390239;
          color: white;
          border-radius: 24px;
          padding: 0.5rem;
        }
      }
      .wrapper {
        margin-top: 5%;
      }
      label {
        font-size: 18px;
        color: #390239;
        font-weight: bold;
      }
      &-table {
        background-color: white;
        .Client-orders {
          .table {
            direction: rtl;
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 600px) {
//   .room-container {
//     &-section {
//       &-btns {
//         justify-content: center;
//       }
//     }
//   }
// }
